import React, { useEffect, useState } from 'react';
import * as paremkStyles from "./paremk.module.css"
import styled from 'styled-components';

function ParamosDezute({ onlyStripe, setOpen, handleSum, handleType, type, sums, stripeSums, payseraSums, inverseTypes }: any) {
    const [sum, setSum] = useState<number>(sums ? sums[0] : 50);
    const [custom, setCustom] = useState<boolean>(false);

    useEffect(() => {
        handleSum(sum)
    }, [sum]);


    function validate(evt: any) {
        var theEvent = evt || window.event;

        // Handle paste
        if (theEvent.type === 'paste') {
            key = (event as any).clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    function isSelected(customB: boolean, amount?: number) {
        if (custom && customB) {
            return true;
        }

        if (!customB && custom) {
            return false;
        }

        if (sum === amount) {
            return true;
        }
        return false;
    }

    return <div className={paremkStyles.boxas}>
        <div style={{ display: 'flex' }}>
            {!onlyStripe && <div onClick={() => handleType('vienas')} style={{
                borderRadius: inverseTypes ? '0 12px 12px 0' : '12px 0 0 12px',
                background: type === 'vienas' ? '#0E558F' : '#fff',
                color: type === 'vienas' ? 'white' : '',
                order: inverseTypes ? 2 : 1

            }}
                className={paremkStyles.pinigas}
            >Vieną kartą</div>}
            <div onClick={() => handleType('menesis')} style={{
                borderLeft: `${onlyStripe ? '' : '0'}`,
                borderRadius: `${onlyStripe ? '12px' : inverseTypes ? '12px 0 0 12px' : '0 12px 12px 0'} `,
                background: type === 'menesis' ? '#0E558F' : '#fff',
                color: type === 'menesis' ? 'white' : '',
                order: inverseTypes ? 1 : 2
            }}
                className={paremkStyles.pinigas}>Kas mėnesį</div>
        </div>

        <div style={{ display: 'flex', marginTop: '16px', gap: '10px' }}>
            
            {type === 'menesis' && <>
                {(stripeSums || sums).map((sum: number) => <div style={{
                    background: isSelected(false, sum) ? '#0E558F' : '#fff',
                    color: isSelected(false, sum) ? 'white' : '',
                }} className={paremkStyles.suma} onClick={() => { setCustom(false); setSum(sum) }}>{sum}€</div>)}
            </>}

            {type === 'vienas' && <>
                {(payseraSums || sums).map((sum: number) => <div style={{
                    background: isSelected(false, sum) ? '#0E558F' : '#fff',
                    color: isSelected(false, sum) ? 'white' : '',
                }} className={paremkStyles.suma} onClick={() => { setCustom(false); setSum(sum) }}>{sum}€</div>)}
            </>}



        </div>

        <div style={{ display: 'flex', marginTop: '16px', gap: '10px' }}>
            <SumaInput
                onKeyPress={(e) => validate(e)}
                style={{
                    background: isSelected(true) ? '#0E558F' : '#fff',
                    color: isSelected(true) ? 'white' : '',
                }} onClick={(e) => { setCustom(true); setSum(+(e.target as any).value); }}
                onChange={(e) => { setSum(+e.target.value) }}
                placeholder='€ kita suma'></SumaInput>
        </div>
        <div>
            <Butonas onClick={() => setOpen(true)}>
                Aukoti
            </Butonas>
        </div>
    </div>
}

export default ParamosDezute;


const SumaInput = styled.input`

        padding: 18px;
        width: 100%;
        flex: 1;
        height: 80px;

        /* Secondary Blue */
        // background: #0E558F;
        /* Button/Blue */
        border: 1px solid #6899C2;
        border-radius: 12px;
        font-weight: 500;
        font-size: 24px;
        line-height: 45px;
        transition: 0.4s;

        text-align: center;

        &:hover {
            background: rgba(63, 138, 187, 0.4);
        cursor: pointer;
	}
        `

const Butonas = styled.button`
        padding: 12px 48px;
        gap: 10px;

        width: 100%;
        height: 52px;

        background: #FF7469;
        border-radius: 12px;

        font-weight: 700;
        font-size: 18px;
        line-height: 140%;

        display: flex;
        align-items: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        border: 0;
        outline none;

        color: #0A2540;

        margin: 24px 0 0 0;
        text-align: center;
        justify-content: center;
        `
